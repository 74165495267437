<div class="container" style="max-width: 600px">
  <form [formGroup]="form" name="login-form" (ngSubmit)="loginUser()" novalidate>
    <div class="row mb-3">
      <h3 class="col-sm-12">Please login</h3>
    </div>
    <div class="form-group row">
      <label for="email" class="col-sm-2 col-form-label">Email</label>
      <div class="col-sm-10">
        <input
          type="email" class="form-control" id="email" name="email" formControlName="email" autocomplete="off" maxlength="150"
          placeholder="Email (required)"/>
        <span class="text-danger" *ngIf="form.get('email').touched && form.get('email').errors">
          <span *ngIf="form.get('email').hasError('required')">
            Email is required
          </span>
          <span *ngIf="form.get('email').hasError('email')">
            Email is invalid
          </span>
        </span>
      </div>
    </div>
    <div class="form-group row">
      <label for="password" class="col-sm-2 col-form-label">Password</label>
      <div class="col-sm-10">

        <div class="input-group" id="show_hide_password">
          <input vpCheckCapsLock
                 (vpCheckCapsLockChange)="isCapsLock = $event"
                 class="form-control"
                 [attr.type]="showPassword ? 'text': 'password'"
                 id="password"
                 formControlName="password"
                 name="password"
                 autocomplete="off"
                 maxlength="150"
                 placeholder="Password (required)">
          <div role="button"
            (click)="showPassword = !showPassword"
            class="input-group-addon d-flex align-items-center rounded-right justify-content-center"
               style="background-color: #ced4da; width: 22px">
            <i [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"
              class="fa text-dark" aria-hidden="true"></i>
          </div>
        </div>
        <div class="text-danger" *ngIf="form.get('password').touched && form.get('password').errors">
          <span *ngIf="form.get('password').hasError('required')">
            Password is required
          </span>
        </div>
        <div class="text-warning" *ngIf="isCapsLock">
          Caps Lock is on!
        </div>
      </div>
    </div>

    <div class="mb-3">
      <alert type="danger" *ngIf="loginError">{{loginError}}</alert>
    </div>
    <div class="mb-3">
      <alert type="info" *ngIf="message">{{message}}</alert>
    </div>

    <div class="d-flex align-items-center">
      <a [routerLink]='["/common/reset-password"]'>Forgotten Password</a>
      <div class="w-25 ml-auto">
        <button type="submit" class="btn btn-primary w-100" name="login-button" [disabled]="form.disabled">Login</button>
      </div>
    </div>
  </form>
</div>
