import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@app/core/services/user.service';

@Injectable()
export class AuthenticatedUserGuard implements CanActivate, CanLoad {
  constructor(private userService: UserService, private router: Router) { }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.userService.isLoggedIn()) {
      this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
      return false;
    }
    return true;
  }

  canLoad() {
    if (!this.userService.isLoggedIn()) {
      return false;
    }
    return true;
  }
}

@Injectable()
export class IsAdminGuard implements CanActivate, CanLoad {
  constructor(private userService: UserService, private router: Router) { }

  canActivate() {
    if (!this.userService.isLoggedIn()) {
      this.router.navigate(['/login']);
      return false;
    }

    if (!this.userService.isAdmin()) {
      this.router.navigate(['/common/unauthorized']);
      return false;
    }
    return true;
  }

  canLoad() {
    return this.canActivate();
  }
}
