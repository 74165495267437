export const constants = {
  ngDateTimeMask: 'hh:mm:ss a dd-MMM-yyyy',
  ngDateTimeMaskDesc: 'yyyy-MM-dd HH:mm',
  ngDateMask: 'dd-MMM-yyyy',
  ngDateMaskUs: 'M/d/yyyy',
  ngDateTimeMaskUs: 'M/d/yyyy hh:mm:ss a',
  momentDateMask: 'DD-MMM-YYYY',
  momentIsoDateMask: 'YYYY-MM-DD',
  locale: 'en-US',
  table: {
    defaultColumnWidth: '200px',
    rowsPerPageOptions: [10, 25, 50, 100],
    currentPageReportTemplate: '{first} to {last} of {totalRecords}'
  },
  defaultTableColumnWidth: '200px',
  modalCancelResults: ['cancel', 'backdrop-click', 'esc']
} as const;
