import { Component } from '@angular/core';
import { FooterPrivacyModel, FooterService } from '@app/core/components/footer/footer.service';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'vp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  data: FooterPrivacyModel;

  constructor(private footerService: FooterService) {
    this.subscribeForFooter();
  }

  getYear(): string {
    const now = new Date();
    return now.getFullYear().toString();
  }

  getCurrentDate(): string {
    return moment().format('DD.MM.YYYY');
  }

  private subscribeForFooter(): void {
    this.footerService.getPrivacy()
      .pipe(
        map(data => {
          data.text = atob(data.text);
          return data;
        })
      )
      .subscribe((response: FooterPrivacyModel) => this.data = response);
  }

}
