import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  constructor(private http: HttpClient) {}

  getJsonHeaders() {
    // eslint-disable-next-line
    return new HttpHeaders({'Content-Type': 'application/json'});
  }

  getPrivacy(): Observable<FooterPrivacyModel> {
    return this.http.get<FooterPrivacyModel>('api/privacy', {headers: this.getJsonHeaders()});
  }
}

export interface FooterPrivacyModel {
  text: string;
  legalStatementLink: string;
  privacyPolicyLink: string;
  cookiePreferences: string;
}
