import { AuthenticatedUserGuard, IsAdminGuard } from './core/guards/auth.guard';
import { LoginComponent } from './login/login.component';
import { Routes } from '@angular/router';
import { VendorManifestResolver } from '@app/vendor-manifest/vendor-manifest.resolver';
import { QuoteSideResolver } from '@app/quote-side/quote-side.resolver';

export const appRoutes: Routes = [
  {path: '', redirectTo: '/quote-side/list', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {
    path: 'quote-side',
    loadChildren: () => import('./quote-side/quote-side.module').then(m => m.QuoteSideModule),
    canActivate: [AuthenticatedUserGuard],
    resolve: {data: QuoteSideResolver}
  },
  {
    path: 'vendor-manifest',
    loadChildren: () => import('./vendor-manifest/vendor-manifest.module').then(m => m.VendorManifestModule),
    canActivate: [AuthenticatedUserGuard],
    resolve: {data: VendorManifestResolver}
  },
  {
    path: 'vendor-management',
    canActivate: [AuthenticatedUserGuard],
    loadChildren: () => import('./vendor-list/vendor-list.module').then(m => m.VendorListModule),
    canLoad: [IsAdminGuard]
  },
  {
    path: 'user-management',
    canActivate: [AuthenticatedUserGuard],
    loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule),
    canLoad: [IsAdminGuard]
  },
  {
    path: 'common',
    loadChildren: () => import('./common/common.module').then(m => m.CommonModule),
  },
  {
    path: 'communication',
    canActivate: [AuthenticatedUserGuard],
    loadChildren: () => import('./communication/communication.module').then(m => m.CommunicationModule)
  },
  {
    path: 'consent-attachments',
    loadChildren: () => import('./consent-attachments/consent-attachments.module').then(m => m.ConsentAttachmentsModule)
  },
  {
    path: 'administration',
    loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
    canLoad: [IsAdminGuard]
  },
  {path: '**', redirectTo: '/quote-side/list'}
];

