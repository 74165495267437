import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastrService } from '@app/shared/services/toastr.service';
import { fromEvent, merge, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { LoaderService } from '@app/core/services/loader.service';
import { PrimeNGConfig } from 'primeng/api';
import { SessionService } from '@app/core/services/session.service';

@Component({
  selector: 'vp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService]
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private toastrService: ToastrService,
    private messageService: MessageService,
    private router: Router,
    public loaderService: LoaderService,
    private primengConfig: PrimeNGConfig,
    private sessionService: SessionService
  ) {
    this.listenOnRouteChanges();
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.listenOnMessageChanges();
    this.listenOnUserActions();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  listenOnRouteChanges() {
    this.router.events.subscribe((event: RouterEvent) => {
      switch (true) {
        case event instanceof NavigationStart:
          Promise.resolve().then(() => this.loaderService.show());
          break;
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError:
          this.loaderService.hide();
          break;
        default:
          break;
      }
    });
  }

  listenOnMessageChanges() {
    this.toastrService.messages$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(messsage => this.messageService.add(messsage));
  }

  listenOnUserActions() {
    merge(
      ...['mousedown', 'mousemove', 'keydown', 'focusin'].map(action => fromEvent(document, action)),
      ...['resize', 'scroll'].map(action => fromEvent(window, action, {capture: true}))
    )
      .pipe(
        debounceTime(500),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.sessionService.setSessionExpire());
  }
}
