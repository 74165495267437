import { formatDate as ngFormatDate } from '@angular/common';
import { constants } from '@app/core/core.const';
import { TableColumn } from '@app/shared/models/table-column';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { PagedListParams } from '@app/shared/models/data-query';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

export const dateFormats = {
  datepicker: 'M/D/YYYY',
  ngIsoDate: 'yyyy-MM-dd',
  version: 'hh:mm:ss aa dd-MMM-yyyy'
};

export const formatAppVersionDate = (date: string) => ngFormatDate(date, dateFormats.version, 'en-US', 'MST') + ' MST';

export const formatDate = (date: string | number | Date): string => (typeof date !== 'number' && !date) ? '' :
  ngFormatDate(date, constants.ngDateMask, constants.locale);

export const formatDateUs = (date: string | number | Date): string => (typeof date !== 'number' && !date) ? '' :
  ngFormatDate(date, constants.ngDateMaskUs, constants.locale);

export const formatDateTimeUs = (date: string | number | Date): string => (typeof date !== 'number' && !date) ? '' :
  ngFormatDate(date, constants.ngDateTimeMaskUs, constants.locale);

export const formatDateTime = (date: string | number | Date): string => (typeof date !== 'number' && !date) ? '' :
  ngFormatDate(date, constants.ngDateTimeMask, constants.locale);

export const formatDateTimeDesc = (date: string | number | Date): string => (typeof date !== 'number' && !date) ? '' :
  ngFormatDate(date, constants.ngDateTimeMaskDesc, constants.locale);

export const formatDateTimeIso = (date: string | number | Date): string => (typeof date !== 'number' && !date) ? '' :
  ngFormatDate(date, dateFormats.ngIsoDate, constants.locale);

export const transformCellValue = <Item>(item: Item, col: TableColumn) => {
  const value = (item as Record<string, unknown>)[col.field];
  return col.pipe ? col.pipe.transform(value, col.pipe) : value;
};

export const formatMomentDate = (date: string | number | Date): string => (typeof date !== 'number' && !date) ? '' :
  moment(date).format(constants.momentDateMask);

export const transformLazyLoadEvent = (event: LazyLoadEvent): PagedListParams => ({
  page: event.first / event.rows,
  pageSize: event.rows,
  ...(event.sortField ? {orderBy: event.sortField} : {}),
  ...(Number.isInteger(event.sortOrder) ? {sortAscending: event.sortOrder > 0} : {}),
});

// eslint-disable-next-line
export const getJsonHttpHeader = () => new HttpHeaders({'Content-Type': 'application/json'});

export const getHttpErrorByField = (response: HttpErrorResponse, field: string): string => {
  const errors: Record<string, string> = response?.error?.errors;
  const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);
  const getError = () => (errors[`action.${capitalize(field)}`] || errors[field] || errors[capitalize(field)]) as string[] | string;

  return typeof errors === 'object' ? (getError() || '') && (getError() as string[]).join(', ') : '';
};

export const objectToFormData = (obj: Record<string, unknown>) => {
  const formData = new FormData();
  const getValue = (val: unknown) => typeof val === 'boolean' || typeof val === 'number' ? String(val) : val as Blob;
  Object.keys(obj).forEach((key => formData.append(key, getValue(obj[key]))));
  return formData;
};

export const lowercaseFirstChar = (value: string) => value && value.charAt(0).toLowerCase() + value.slice(1);
