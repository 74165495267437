import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { Observable, of, Subject } from 'rxjs';
import { filter, debounceTime, switchMap, shareReplay, map, distinctUntilChanged, tap } from 'rxjs/operators';
import { ApplicationInfoService } from '@app/core/services/application-info.service';
import { NavMenuService } from '@app/core/components/nav-menu/nav-menu.service';

@Component({
  selector: 'vp-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  unreadMessages$: Observable<number>;
  applicationInfo$ = this.applicationInfoService.getApplicationInfo();
  sendNotification = true;
  private notificationSubject = new Subject<boolean>();

  isAdmin = () => this.userService.isAdmin();
  isVendorUser = () => this.userService.isVendorUser();
  collapse = () => this.isExpanded = false;
  toggle = () => this.isExpanded = !this.isExpanded;
  getNavClass = () => this.userService.isLoggedIn() ? 'navbar-expand-lg' : 'navbar-expand';
  getRocheLogoClass = () => this.userService.isLoggedIn() && this.isAdmin() ? 'd-lg-none d-xl-block' : '';
  switchNotification = () => this.notificationSubject.next(this.sendNotification = !this.sendNotification);
  logout = () => this.userService.logout().add(() => this.router.navigateByUrl('/login'));

  constructor(
    private router: Router,
    public userService: UserService,
    private navMenuService: NavMenuService,
    private applicationInfoService: ApplicationInfoService
  ) {
    this.unreadMessages$ = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd && this.userService.isLoggedIn()),
        debounceTime(0),
        switchMap(() => this.userService.isLoggedIn() ? this.navMenuService.getUnreadMessagesCount() : of(0)),
        shareReplay(1)
      );

    this.router.events
      .pipe(
        map(() => this.userService.isLoggedIn()),
        distinctUntilChanged(),
        switchMap(isLoggedIn => isLoggedIn ? this.userService.isNotificationEnabledForUser() : of(true)),
        tap(sendNotification => this.sendNotification = sendNotification)
      )
      .subscribe();
  }

  ngOnInit() {
    this.notificationSubject
      .pipe(
        filter(() => this.userService.isLoggedIn()),
        debounceTime(150),
        distinctUntilChanged(),
        switchMap(enabled => this.userService.setNotification(enabled))
      )
      .subscribe();
  }
}
