import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SessionService } from '@app/core/services/session.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private sessionService: SessionService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${this.sessionService.getAuthToken()}`)
    });
    return next.handle(clonedRequest);
  }
}

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private sessionService: SessionService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      tap(
        () => this.sessionService.setSessionExpire(),
        (err: HttpErrorResponse) => {
          if (err.status === 401) {
            localStorage.removeItem('auth_token');
            localStorage.removeItem('loggedInUser');
            const message = window.btoa(`401 - Unauthorized Access / Session Expired`);
            const returnUrl = this.router.url;
            this.router.navigate(['/login'], {queryParams: {message, returnUrl}});
          }
        })
    );
  }
}
