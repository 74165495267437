import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '@app/core/services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const ignoredRequests: string[] = [];

    if (req.url && ignoredRequests.some(ignoreReq => req.url.includes(ignoreReq))) {
      return next.handle(req);
    } else {
      Promise.resolve().then(() => this.loaderService.show());
      return next.handle(req)
        .pipe(
          finalize(() => Promise.resolve().then(() => this.loaderService.hide()))
        );
    }
  }
}
