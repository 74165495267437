<nav class="navbar navbar-light bg-light" [ngClass]="getNavClass()">
  <a class="navbar-brand" href="#">Vendor Portal</a>
  <button
    class="navbar-toggler" type="button" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
    (click)="toggle()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" [collapse]="!isExpanded" [isAnimated]="true">
    <ul class="navbar-nav w-100">
      <ng-container *ngIf="userService.loggedIn">
        <li class="nav-item" routerLinkActive="link-active" [routerLinkActiveOptions]='{ exact: true }'>
          <a class="nav-link" routerLink="/quote-side/list" (click)='collapse()'>
            <span class="glyphicon glyphicon-th-list"></span> Quote Side
          </a>
        </li>
        <li class="nav-item" routerLinkActive="link-active">
          <a class="nav-link" routerLink="/vendor-manifest/list" (click)='collapse()'>
            <span class="glyphicon glyphicon-th-list"></span> Vendor Manifest
          </a>
        </li>
        <li class="nav-item" routerLinkActive="link-active" *ngIf="isAdmin()">
          <a class="nav-link" routerLink="/vendor-management/list" (click)='collapse()'>
            <span class="glyphicon glyphicon-th-list"></span> Vendor List
          </a>
        </li>
        <li class="nav-item" routerLinkActive="link-active" *ngIf="isAdmin()">
          <a class="nav-link" routerLink="/user-management/list" (click)='collapse()'>
            <span class="glyphicon glyphicon-th-list"></span> User Management
          </a>
        </li>
        <li class="nav-item" routerLinkActive="link-active" [routerLinkActiveOptions]='{ exact: true }'>
          <a class="nav-link" routerLink="/communication/list" (click)='collapse()'>
            <span class="glyphicon glyphicon-th-list"></span> Communication
            <span class="text-danger font-weight-bold text-nowrap" *ngIf="(unreadMessages$ | async) > 0">
              {{(unreadMessages$ | async)}} New!
            </span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive="link-active">
          <a class="nav-link" routerLink="/consent-attachments" (click)='collapse()'>
            <span class="glyphicon glyphicon-th-list"></span> Consent Attachments
          </a>
        </li>
        <li class="nav-item" routerLinkActive="link-active" *ngIf="isAdmin()">
          <a class="nav-link" routerLink="/administration/admin-panel" (click)='collapse()'>
            <span class="glyphicon glyphicon-th-list"></span> Admin Panel
          </a>
        </li>
        <li class="nav-item flex-grow-1"></li>
        <li class="nav-item text-center">
          <button
            type="button"
            class="btn btn-light ml-3 mr-3"
            popover="Enable or disable email notification from the application."
            placement="bottom"
            triggers="mouseenter:mouseleave"
            (click)="switchNotification()">
            <i class="far vp-btn-icon" [ngClass]="sendNotification ? 'fa-bell' : 'fa-bell-slash not-skip'"></i>
          </button>
        </li>
        <li class="nav-item" routerLinkActive="link-active">
          <a routerLink="/common/set-password" class="nav-link" (click)='collapse()'>
            {{userService?.loggedInUser?.displayName}}
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="" class="nav-link" (click)="collapse(); logout()" href="#">Logout</a>
        </li>
      </ng-container>
      <li class="nav-item" [class.ml-auto]="!userService.isLoggedIn()" [ngClass]="getRocheLogoClass()">
        <img class="roche-logo" src="assets/logo.png" alt="Roche"/>
        <div *ngIf="applicationInfo$ | async as applicationInfo" class="app-info text-black-50">
          Version: {{applicationInfo.version}}, <br>
          {{applicationInfo.buildDate}}
        </div>
      </li>
    </ul>
  </div>
</nav>
