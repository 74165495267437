import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private sessionLogout: number = null;
  private timeOutInMinutes = 20;

  constructor(
    private router: Router,
    private modalService: BsModalService,
  ) { }

  getAuthToken() {
    return localStorage.getItem('auth_token');
  }

  setSessionExpire() {
    if (this.sessionLogout != null) {
      clearTimeout(this.sessionLogout);
    }

    if (localStorage.getItem('loggedInUser') != null) {
      this.sessionLogout = window.setTimeout(() => {
        this.modalService.hide(1);
        this.router.navigate(['/login'], {queryParams: {message: window.btoa(`Session Expired`)}});
      }, this.timeOutInMinutes * 60 * 1000);
    }
  }
}
