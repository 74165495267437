import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[vpCheckCapsLock]'
})
export class CheckCapsLockDirective {
  @Output() vpCheckCapsLockChange = new EventEmitter<boolean>();

  @HostListener('document:mousedown', ['$event'])
  @HostListener('document:keydown', ['$event'])
  @HostListener('document:keyup', ['$event'])
  onEvent(e: MouseEvent | KeyboardEvent) {
    this.vpCheckCapsLockChange.emit(e.getModifierState?.('CapsLock'));
  }
}
