import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AbstractControl } from '@angular/forms';
import { timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ChangePassword, InsertableUser, ConfirmUser, LoggedInUser } from '@app/user-management/user-management.model';
import { getJsonHttpHeader } from '@app/shared/helpers';
import { GridCacheService } from '@app/shared/services/grid-cache.service';

interface LoginResult {
  jwt: string;
  displayName: string;
  vendorId: string;
}

@Injectable({providedIn: 'root'})
export class UserService {
  loggedInUser: LoggedInUser;
  loggedIn: boolean;
  decodedJwtData: any;

  private accountApi = '/api/account';

  constructor(
    private http: HttpClient,
    private gridCacheService: GridCacheService
  ) {
    this.loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
    if (!this.loggedInUser) {
      this.loggedInUser = new LoggedInUser();
    }
    this.loggedIn = !!localStorage.getItem('loggedInUser');
  }

  getJsonHeaders() {
    // eslint-disable-next-line
    return new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
  }

  login(email: string, password: string) {
    return this.http
      .post<LoginResult>(`${this.accountApi}/login`, {email, password})
      .pipe(map(result => {
        const jwt = JSON.parse(result.jwt);
        this.gridCacheService.putCache();
        this.loggedIn = true;
        this.loggedInUser.email = email;
        this.loggedInUser.userId = Number(jwt.id);
        this.loggedInUser.vendorId = jwt.vendorId;
        const token = jwt.auth_token;
        const jwtData = token.split('.')[1];
        const decodedJwtJsonData = window.atob(jwtData);
        this.decodedJwtData = JSON.parse(decodedJwtJsonData);

        this.loggedInUser.role = this.decodedJwtData.rol;
        this.loggedInUser.displayName = result.displayName;
        this.loggedInUser.vendorId = result.vendorId;

        localStorage.setItem('loggedInUser', JSON.stringify(this.loggedInUser));
        localStorage.setItem('auth_token', jwt.auth_token);
      }));
  }

  isLoggedIn() {
    return this.loggedIn;
  }

  isAdmin() {
    return this.loggedInUser.role === 'Admin';
  }

  isVendorUser() {
    return (this.loggedInUser.role === 'VendorUser');
  }

  logout() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('loggedInUser');
    this.loggedIn = false;
    return this.http.post(`${this.accountApi}/logout`, {}).subscribe();
  }

  doesEmailExists(control: AbstractControl) {
    const email = control.value;
    return timer(500).pipe(
      switchMap(() => this.http.post(`api/user/doesEmailExists`, JSON.stringify(email), {headers: this.getJsonHeaders()})),
      map(res => res ? {alreadyExists: true} : null)
    );
  }

  doesDisplayNameExists(control: AbstractControl) {
    const displayName = control.value;
    return timer(500).pipe(
      switchMap(() => this.http.post(`api/user/doesDisplayNameExists`, JSON.stringify(displayName), {headers: this.getJsonHeaders()})),
      map(res => res ? {alreadyExists: true} : null)
    );
  }

  forgottenPassword(email: string) {
    return this.http.post(`${this.accountApi}/forgottenPassword`, JSON.stringify(email), {headers: this.getJsonHeaders()});
  }

  changePassword(model: ChangePassword) {
    return this.http.post(`${this.accountApi}/changePassword`, model);
  }

  createNew(user: InsertableUser) {
    return this.http.post(`${this.accountApi}/createNew`, user);
  }

  confirmUser(model: ConfirmUser) {
    return this.http.post(`${this.accountApi}/confirmUser`, model);
  }

  getVendorId() {
    return this.loggedInUser.vendorId;
  }

  getUserId() {
    return this.loggedInUser.userId;
  }

  setNotification(enabled: boolean) {
    return this.http.post<void>(`${this.accountApi}/notification`, enabled, {headers: getJsonHttpHeader()});
  }

  isNotificationEnabledForUser() {
    return this.http.get<boolean>(`${this.accountApi}/notification`, {headers: getJsonHttpHeader()});
  }
}

