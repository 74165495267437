export interface User {
  id: number;
  displayName: string;
  email: string;
  userRole: string;
  vendorName: string;
  vendorId: number;
  phoneNumber: string;
  isAdmin: boolean;
  isObsolete: boolean;
}

export class InsertableUser {
  displayName: string;
  email: string;
  password: string;
  confirmPassword: string;
  vendorId: number;
  phoneNumber: string;
  isAdmin: boolean;
}

export class ChangePassword {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export class ConfirmUser {
  userId: number;
  code: string;
  newPassword: string;
  confirmPassword: string;
}

export class LoggedInUser {
  token: string;
  email: string;
  displayName: string;
  userId: number;
  role: string;
  vendorId: string;
}
