import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DictionariesMap, DictionaryEntry } from '@app/shared/models/dictionary-entry';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class DictionaryService {
  vendorsDictionary: DictionaryEntry[] = [];

  private allDictionariesSubject = new BehaviorSubject<void>(null);
  private allDictionariesValue: DictionariesMap;
  allDictionaries$ = this.allDictionariesSubject.pipe(
    switchMap(() => this.http.get<DictionariesMap>('api/Dictionary/all')),
    tap(data => this.allDictionariesValue = data),
    shareReplay(1)
  );

  constructor(private http: HttpClient) { }

  getDictionaryByName(name: string) {
    return this.allDictionariesValue?.[name]?.filter(item => !item.isObsolete) || [];
  }

  refreshVendorsDictionary() {
    this.getVendors().subscribe(result => this.vendorsDictionary = result);
  }

  getVendors() {
    return this.http.get<DictionaryEntry[]>('api/Dictionary/Vendors');
  }

  getNonObsoleteVendors() {
    return this.getVendors().pipe(map(vendors => vendors.filter(vendor => !vendor.isObsolete)));
  }
}
