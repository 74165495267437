import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { shareReplay, tap } from 'rxjs/operators';

interface SettingsMap {
  [name: string]: unknown;
}

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  private static url = 'api/resource-settings';
  private allSettingsMap: SettingsMap;
  allSettings$ = this.http.get<SettingsMap>(SettingService.url).pipe(
    tap(data => this.allSettingsMap = data),
    shareReplay(1)
  );
  getSettingByKey = (key: string) => this.allSettingsMap?.[key];

  constructor(
    private http: HttpClient
  ) { }

  saveSetting(resourceName: string, setting: unknown) {
    this.allSettingsMap[resourceName] = setting;
    return this.http.post<void>(SettingService.url, {resourceName, setting});
  }
}
