import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './core/components/nav-menu/nav-menu.component';
import { LoginComponent } from './login/login.component';
import { AuthenticatedUserGuard, IsAdminGuard } from './core/guards/auth.guard';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor, JwtInterceptor } from './core/interceptors/http.interceptor';
import { FooterComponent } from './core/components/footer/footer.component';
import { appRoutes } from './app.routes';
import { ToastModule } from 'primeng/toast';
import { ProgressBarModule } from 'primeng/progressbar';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { LoaderInterceptor } from '@app/core/interceptors/loader.interceptor';
import { SharedModule } from '@app/shared/shared.module';
import { registerAllModules } from 'handsontable/registry';

// register Handsontable's modules
registerAllModules();

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavMenuComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,

    ToastModule,
    ProgressBarModule,
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    AlertModule.forRoot(),
    CollapseModule.forRoot(),

    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false,
        scrollPositionRestoration: 'enabled',
        relativeLinkResolution: 'legacy'
      }
    ),
    SharedModule
  ],
  providers: [
    AuthenticatedUserGuard, IsAdminGuard,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
