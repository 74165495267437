import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TreeTableModule } from 'primeng/treetable';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { PanelModule } from 'primeng/panel';
import { BlockUIModule } from 'primeng/blockui';
import { NgSelectModule } from '@ng-select/ng-select';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FlagPipe } from '@app/shared/pipes/flag.pipe';
import { DateTimePipe } from '@app/shared/pipes/date-time.pipe';
import { DatePipe } from '@app/shared/pipes/date.pipe';
import { DateTimeDescPipe } from '@app/shared/pipes/date-time-desc.pipe';
import { DateUsPipe } from '@app/shared/pipes/date-us.pipe';
import { CurrencyPipe } from '@app/shared/pipes/currency.pipe';
import { DecimalPipe } from '@app/shared/pipes/decimal.pipe';
import { DateTimeUsPipe } from '@app/shared/pipes/date-time-us.pipe';
import { MessageModalComponent } from '@app/shared/components/message-modal/message-modal.component';
import { SearchControlComponent } from '@app/shared/components/search-control/search-control.component';
import { InnerTableComponent } from './components/inner-table/inner-table.component';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { BlockPanelComponent } from './components/block-panel/block-panel.component';
import { EditColumnsComponent } from './components/edit-columns/edit-columns.component';
import { EditColumnsDialogComponent } from './components/edit-columns/edit-columns-dialog/edit-columns-dialog.component';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { CheckCapsLockDirective } from '@app/shared/directives/check-caps-lock.directive';

@NgModule({
  declarations: [
    DatePipe,
    FlagPipe,
    DateTimePipe,
    DateTimeDescPipe,
    DateUsPipe,
    CurrencyPipe,
    DecimalPipe,
    DateTimeUsPipe,

    CheckCapsLockDirective,

    MessageModalComponent,
    SearchControlComponent,
    InnerTableComponent,
    BlockPanelComponent,
    EditColumnsComponent,
    EditColumnsDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,

    NgSelectModule,

    TableModule,
    ToastModule,
    TreeTableModule,
    ButtonModule,
    RippleModule,
    PanelModule,
    BlockUIModule,
    FileUploadModule,
    MultiSelectModule,
    DialogModule,
    CheckboxModule,

    TypeaheadModule.forRoot(),
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    BsDatepickerModule.forRoot()
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,

    NgSelectModule,

    TableModule,
    ToastModule,
    TreeTableModule,
    ButtonModule,
    RippleModule,
    PanelModule,
    BlockUIModule,
    FileUploadModule,
    MultiSelectModule,
    DialogModule,
    CheckboxModule,

    TypeaheadModule,
    AlertModule,
    ModalModule,
    PopoverModule,
    BsDatepickerModule,

    DatePipe,
    FlagPipe,
    DateTimePipe,
    DateTimeDescPipe,
    DateUsPipe,
    CurrencyPipe,
    DecimalPipe,
    DateTimeUsPipe,

    CheckCapsLockDirective,

    MessageModalComponent,
    SearchControlComponent,
    InnerTableComponent,
    BlockPanelComponent,
    EditColumnsComponent
  ]
})
export class SharedModule {}
