import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { UserService } from '@app/core/services/user.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'vp-login-form',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  form = this.formBuilder.group({
    email: [null, {validators: [Validators.required, Validators.maxLength(150), Validators.email]}],
    password: [null, Validators.required]
  });
  loginError: '';
  message = '';
  returnTo = '/';
  isCapsLock = false;
  showPassword = false;

  constructor(
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.userService.logout();
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params: Params) => {
      if (params.message) {
        this.message = window.atob(params.message);
      }
      if (params.returnUrl) {
        this.returnTo = params.returnUrl;
      }
    });
  }

  loginUser() {
    if (!this.form.valid) {
      return;
    }

    this.loginError = '';
    this.message = '';

    this.form.disable();
    const {email, password} = this.form.getRawValue();
    this.userService.login(email, password)
      .subscribe({
        next: () => this.router.navigateByUrl(this.returnTo),
        error: (error: HttpErrorResponse) => {
          this.form.enable();
          if (error.status === 400) {
            this.loginError = error.error.login_failure;
          }
        }
      });
  }
}
