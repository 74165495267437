import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Message } from 'primeng/api/message';

@Injectable({
  providedIn: 'root'
})
export class ToastrService {
  private messagesSubject = new Subject<Message>();
  messages$ = this.messagesSubject.asObservable();
  private formatMsg = (msg: string | unknown) => typeof msg === 'string' ? msg : JSON.stringify(msg);
  add = (message: Message) => this.messagesSubject.next(message);
  addError = (error: string) => this.add({severity: 'error', summary: 'Error', detail: this.formatMsg(error), life: 8 * 1000});
  addSuccess = (detail: string) => this.add({severity: 'success', summary: 'Info', detail: this.formatMsg(detail), life: 5 * 1000});
}
