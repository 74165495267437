import { Injectable } from '@angular/core';
import { map, shareReplay, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UserService } from '@app/core/services/user.service';

export interface Status {
  key: string;
  displayItem: string;
}

interface StatusMap {
  quotestatus: Status[];
  manifeststatus: Status[];
}

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  private allStatusesValue: StatusMap;
  allStatuses$ = this.http.get<StatusMap>('api/status').pipe(
    map(data => ({...data, quotestatus: data.quotestatus.filter(status => this.userService.isAdmin() || status.key !== 'BridgeCanceled')})),
    tap((data: StatusMap) => this.allStatusesValue = data),
    shareReplay(1)
  );
  getStatusesByName = (name: keyof StatusMap) => this.allStatusesValue?.[name];

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) { }
}
