import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { scan, tap } from 'rxjs/operators';

export type GridCacheKey = 'quote-side-lazy-load' | 'quote-side-search' | 'vendor-list-search' |
  'vendor-manifest-lazy-load' | 'vendor-manifest-search' |
  'communication-list-lazy-load' | 'communication-list-search' | 'vendor-consent-attachments-lazy-load' |
  'admin-consent-attachments-lazy-load' | 'user-management-search' | 'consent-attachments-search';
type AllGridCache = Partial<Record<GridCacheKey, unknown>>;

@Injectable({
  providedIn: 'root'
})
export class GridCacheService {
  private gridCacheChanges = new Subject<AllGridCache>();
  private allGridCache = new BehaviorSubject<AllGridCache>({});

  getCache = (name: GridCacheKey) => this.allGridCache.getValue()[name];
  putCache = (params: AllGridCache = null) => this.gridCacheChanges.next(params);

  constructor() {
    this.gridCacheChanges.pipe(
      scan((acc, data) => data ? {...acc, ...data} : {}, {}),
      tap(cache => this.allGridCache.next(cache))
    ).subscribe();
  }
}
