import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApplicationInfo } from '@app/shared/models/application-info';
import { map } from 'rxjs/operators';
import { formatAppVersionDate } from '@app/shared/helpers';

@Injectable({providedIn: 'root'})
export class ApplicationInfoService {
  constructor(
    private http: HttpClient
  ) {}

  getApplicationInfo() {
    return this.http.get<ApplicationInfo>(`api/info`)
      .pipe(
        map(info => ({...info, buildDate: formatAppVersionDate(info.buildDate)}))
      );
  }
}
