import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavMenuService {
  constructor(
    private http: HttpClient
  ) { }

  getUnreadMessagesCount(): Observable<number> {
    return this.http.get<{ unreadMessages: number }>('api/communication/getUnreadMessagesCount')
      .pipe(map(result => result.unreadMessages));
  }
}
