<p-progressBar
  *ngIf="(loaderService.isLoading$ | async)"
  mode="indeterminate"
  class="top-loader"
  [style]="{height: '6px'}">
</p-progressBar>
<div class="d-flex flex-column h-100">
  <p-toast [baseZIndex]="2000" [position]="'bottom-center'"></p-toast>
  <vp-nav-menu></vp-nav-menu>
  <div class="flex-grow-1 mt-3">
    <router-outlet></router-outlet>
  </div>
  <vp-footer></vp-footer>
</div>
