<footer class="pt-3 footer text-muted">
  <div class="container">
    <div class="row mb-2">
      <span class="d-block col-md-3 hoffman-title">© {{getYear()}} F. Hoffmann-La Roche Ltd {{getCurrentDate()}}</span>
      <a class="col-md-2" [href]="data?.legalStatementLink">Legal Statement</a>
      <a class="col-md-2" [href]="data?.privacyPolicyLink">Privacy Policy</a>
      <a class="col-md-2 ot-sdk-show-settings" style="cursor: pointer">Cookie Preferences</a>
    </div>
    <p [innerHTML]="data?.text">
    </p>
  </div>
</footer>


