import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { DictionaryService } from '@app/shared/services/dictionary.service';
import { take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { StatusService } from '@app/shared/services/status.service';
import { SettingService } from '@app/shared/services/setting.service';

@Injectable({
  providedIn: 'root'
})
export class VendorManifestResolver implements Resolve<unknown> {
  constructor(
    private dictionaryService: DictionaryService,
    private statusService: StatusService,
    private settingService: SettingService
  ) {}

  resolve() {
    return combineLatest([
      this.dictionaryService.allDictionaries$,
      this.statusService.allStatuses$,
      this.settingService.allSettings$
    ]).pipe(
      take(1)
    );
  }
}
